import React, { useState, useEffect} from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import urlSlug from 'url-slug';
import Layout from './layout';
import Banner from './banner';
import usePhones from '../hooks/usePhones';
import Seo from './seo'
import Htmltext from './htmlText';

const ProfilesContent = styled.ul`
    max-width: 1200px;
    width: 95%;
    margin: 4rem auto 2rem auto;

    @media (min-width: 480px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2rem;
        column-gap: 2rem;
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const Card = styled.div`
    border: 1px solid #E1E1E1;
    background-color: #313282;
   
`;

const DetailContent = styled.div`
    padding: 2rem;
     h3{
        font-family: 'Lato', sans-serif;
        margin: 0 0 2rem 0;
        font-size: 2.4rem;
        color: #ffcccc;  
    }
    p{
        font-size: 1.2rem;
        color: #ffcccc;    
    } 
`;

const ProfileLink = styled(Link)`
    img {
        max-width: 100%;
    }
`;


export const query = graphql`
    query(
        $id : String!, 
        $category_ass_size : String, 
        $category_boobs_size : String, 
        $category_city : String, 
        $category_contexture : String, 
        $category_eye_color : String, 
        $category_hair_color : String, 
        $category_skin_color : String  
    ) {
        
        page: strapiEntityCategoryPages ( id: {eq: $id} ) {
            banner_h1
            banner_h2
            seo_title
            seo_description
            seo_keywords
            en_banner_h1
            en_banner_h2
            en_seo_title
            en_seo_description
            en_seo_keywords
            start_text
            end_text
            start_text_en
            end_text_en

        }

        profiles: allStrapiEntityProfiles(filter: {
            category_hair_color: {name: { glob: $category_hair_color}}, 
            category_ass_size: {name: { glob: $category_ass_size}}, 
            category_boobs_size: {name: { glob: $category_boobs_size}}, 
            category_city: {name: { glob: $category_city}}, 
            category_contexture: {name: { glob: $category_contexture}}, 
            category_eye_color: {name: { glob: $category_eye_color}}, 
            category_skin_color: {name: { glob: $category_skin_color}}
        }) {
            nodes {
                id
                title
                description
                en_description
                image_one {
                    sharp: childImageSharp {
                        fluid( maxWidth: 400 , maxHeight: 400 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }    
`;

const Category = (props) => {
    
    const lan = props.pageContext.lan;
    const profileList = props.data.profiles.nodes.map( profile => ({
        id: profile.id,
        title: profile.title,
        description: lan === 'es' ? profile.description : profile.en_description,
        picture: profile.image_one
    }));

    console.log();  
    
    const textstart = lan === 'es'? props.data.page.start_text:props.data.page.start_text_en
    const textend = lan === 'es'? props.data.page.end_text:props.data.page.end_text_en

    const phones = usePhones();
    const { banner_h1,  banner_h2, seo_title, seo_description, seo_keywords, en_banner_h1, en_banner_h2, en_seo_title, en_seo_description, en_seo_keywords  } = props.data.page;
    const [ profiles ] = useState(profileList);
    const [ initalLoad, setInitalLoad ] = useState(true);
    const [ nextCount, setNextCount ] = useState(0);
    const [ scrolledProfiles, setScrolledProfiles ] = useState([]);
    const profileSize = profiles.length;

    useEffect(() => {
        if(initalLoad){
            console.log('primera carga')
            if( profileSize >= 3 ){
                setScrolledProfiles(profiles.slice(0,3));
                setNextCount(3);   
            } else {
                setScrolledProfiles(profiles.slice(0,profileSize));
            }
            setInitalLoad(false);
        } else {
            
            if( profileSize >= 3 ){
                if( profileSize >= 3 ){
                    const handleScroll = () => {
                        const scrolledY = window.scrollY;
                        const scrollable = document.documentElement.scrollHeight - window.innerHeight;
                        const isScrolled = (Math.floor(scrolledY) + 2) >= scrollable;
                        if(isScrolled){
                            const currentCount = nextCount;
                            console.log('next count', currentCount);
                            if(currentCount === profiles.length){
                                console.log('complete array');
                            } else if(currentCount + 3 > profiles.length) {
                                console.log('last iteration');
                                let loadMore = profiles.slice(currentCount, profiles.length);
                                let newArray = scrolledProfiles;  
                                loadMore.map( more => ( newArray.push(more)));
                                setScrolledProfiles(newArray);
                                setNextCount(profiles.length);
                            } else {
                                console.log('common iteration');
                                let loadMore = profiles.slice(currentCount, currentCount + 3);
                                let newArray = scrolledProfiles;
                                loadMore.map( more => ( newArray.push(more)));
                                setScrolledProfiles(newArray);
                                setNextCount(currentCount + 3);
                            }
                        }
                    };
                    document.addEventListener('scroll', handleScroll, { passive: true });
                    
                    return () => {
                        document.removeEventListener('scroll', handleScroll);    
                    }
                }
            } else{
                console.log('no more array');    
            }
        }
        
    }, [ profiles, scrolledProfiles, nextCount, initalLoad, profileSize ])

    return ( 
        <Layout lan={lan}>
            <Seo 
                title={ lan === 'es' ? seo_title : en_seo_title }
                keywords={ lan === 'es' ? seo_keywords : en_seo_keywords }
                description={ lan === 'es' ? seo_description : en_seo_description }
            />
            <Banner 
                banner_h1={ lan === 'es' ? banner_h1 : en_banner_h1 }
                banner_h2={ lan === 'es' ? banner_h2 : en_banner_h2 }
                phones={phones[0].phones}
            />
            <main>
            <Htmltext text={textstart}/>
                <ProfilesContent>
                    { scrolledProfiles.map( profile => (
                        <Card key={profile.id}>
                            <ProfileLink to={ (lan === 'es' ? '/dama-de-compañia-' : '/en-companions-ladies-') + urlSlug(profile.title) }>
                                <Image fluid={profile.picture.sharp.fluid}/>
                            </ProfileLink>
                            <DetailContent >
                                <h3>{profile.title}</h3>
                                <p>{getShotDescription( profile.description ) }</p>
                            </DetailContent>
                        </Card>
                    ))}
                </ProfilesContent>
                <Htmltext text={textend}/>
            </main>
        </Layout>
    );
}
 
export default Category;

function getShotDescription( description ){
    const ptag = description !== null && description !== '' ? description.split(' ') : '';
    if(ptag.length > 0 ){
        return ptag.length > 30 ? ptag.slice( 0, 30).join(' ') + '  ...' : ptag.slice( 0, ptag.length ).join(' ');
    }else{
        return '';
    }
}